import {
    StoreSwitcherComponent as SourceStoreSwitcher,
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import './StoreSwitcher.override.style';

/** @namespace Pwa/Component/StoreSwitcher/Component */
export class StoreSwitcherComponent extends SourceStoreSwitcher {
    // TODO implement logic
}

export default StoreSwitcherComponent;

import { Field } from '@tilework/opus';

import {
    ConfigQuery as SourceConfigQuery,
} from 'SourceQuery/Config.query';

/** @namespace Pwa/Query/Config/Query */
export class ConfigQuery extends SourceConfigQuery {
    _getAlgoliaConfigFields() {
        return [
            'appId',
            'searchOnlyApiKey',
            'productsIndex',
            'categoriesIndex',
            'pagesIndex',
        ];
    }

    _getFlixMediaConfigFields() {
        return [
            'distributor_id',
        ];
    }

    _getAlgoliaConfigField() {
        return new Field('algolia')
            .addFieldList(this._getAlgoliaConfigFields());
    }

    _getFlixMediaConfigField() {
        return new Field('flixmedia')
            .addFieldList(this._getFlixMediaConfigFields());
    }

    _getSuccessPageConfigFields() {
        return [
            'needHelpLink',
            'orderDetailLink',
        ];
    }

    _getSuccessPageConfigField() {
        return new Field('successPageConfig')
            .addFieldList(this._getSuccessPageConfigFields());
    }

    _getStoreConfigFields() {
        const fields = super._getStoreConfigFields();
        fields.push(this._getAlgoliaConfigField());
        fields.push(this._getFlixMediaConfigField());
        fields.push(this._getSuccessPageConfigField());

        return fields;
    }
}

export default new ConfigQuery();

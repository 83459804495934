import PropTypes from 'prop-types';

export const McMenuNodeShape = {
    url: PropTypes.string,
    name: PropTypes.string,
    cms_block: PropTypes.string,
    path: PropTypes.string,
    level: PropTypes.number,
};

McMenuNodeShape.nodes = PropTypes.arrayOf(PropTypes.shape(McMenuNodeShape));

export const McMenuNodeType = PropTypes.shape(McMenuNodeShape);

export const McMenuType = PropTypes.shape({
    nodes: PropTypes.arrayOf(McMenuNodeType),
});
